export const VALIDGUESSES = [
  'абага',
  'абзар',
  'абзый',
  'абруй',
  'абхаз',
  'авыру',
  'аерма',
  'аерча',
  'азлык',
  'азман',
  'айгыр',
  'айлык',
  'акбур',
  'акбүз',
  'аккош',
  'аккүз',
  'аклан',
  'аклык',
  'аксым',
  'актүш',
  'акула',
  'акшар',
  'алача',
  'алаша',
  'алдак',
  'алдар',
  'алиһә',
  'алкыш',
  'алмаш',
  'алтын',
  'алучы',
  'алыча',
  'андыз',
  'аңкау',
  'апара',
  'аракы',
  'арата',
  'аркан',
  'аркау',
  'артым',
  'артыш',
  'арчан',
  'аршын',
  'аръяк',
  'аслам',
  'аслык',
  'астар',
  'атама',
  'атлам',
  'атучы',
  'ахшам',
  'ачкыч',
  'ачлык',
  'ашлык',
  'аякчу',
  'бабай',
  'бавыр',
  'базар',
  'бакра',
  'бакча',
  'бакыр',
  'балак',
  'балан',
  'балта',
  'балык',
  'барыш',
  'басма',
  'басым',
  'батыр',
  'башак',
  'белем',
  'белен',
  'белеш',
  'беләк',
  'беләү',
  'берек',
  'берле',
  'бетчә',
  'бәбәй',
  'бәбәк',
  'бәбкә',
  'бәвел',
  'бәдән',
  'бәйге',
  'бәкәл',
  'бәлеш',
  'бәләк',
  'бәлҗә',
  'бәндә',
  'бәпкә',
  'бәрән',
  'бәхет',
  'бәхәс',
  'бибек',
  'бизәк',
  'бикрә',
  'билге',
  'биләү',
  'бирем',
  'бирән',
  'бирнә',
  'бистә',
  'бишек',
  'биюче',
  'богау',
  'богыл',
  'бодай',
  'божан',
  'боҗра',
  'боҗыр',
  'бозау',
  'болак',
  'болан',
  'болын',
  'болыт',
  'борай',
  'борау',
  'борма',
  'борча',
  'борын',
  'борыс',
  'борыч',
  'ботак',
  'ботка',
  'бөҗәк',
  'бөреш',
  'бөрмә',
  'бугаз',
  'букча',
  'булат',
  'буран',
  'бурка',
  'бурыч',
  'бурят',
  'бүкән',
  'бүксә',
  'бүлек',
  'бүлем',
  'бүләк',
  'бүлмә',
  'бүрек',
  'бүсер',
  'быргы',
  'вакыт',
  'варис',
  'ватан',
  'вафат',
  'вәзир',
  'вәкил',
  'вөҗүд',
  'гавам',
  'гадәт',
  'газап',
  'гаилә',
  'гакыл',
  'галәм',
  'галим',
  'гамәл',
  'гарәп',
  'гарип',
  'гасыр',
  'гауга',
  'гәудә',
  'гидай',
  'гозер',
  'гомер',
  'гөблә',
  'гөләп',
  'гөман',
  'гөмбә',
  'гөнаһ',
  'гөслә',
  'давыл',
  'даган',
  'даирә',
  'дебет',
  'дегет',
  'дәвам',
  'дәвер',
  'дәлил',
  'дәлия',
  'дәрес',
  'дәрья',
  'димче',
  'динче',
  'дисбе',
  'дистә',
  'дөмбе',
  'дөнья',
  'дүдәк',
  'елдаш',
  'ермак',
  'ешлык',
  'әгъза',
  'әдрәс',
  'әйбер',
  'әйрән',
  'әкият',
  'әлүчә',
  'әнкәй',
  'әнчек',
  'әрәтә',
  'әрлән',
  'әрләш',
  'әрмәк',
  'әрмән',
  'әртил',
  'әсбап',
  'әткәй',
  'әтраф',
  'әүлия',
  'әфсен',
  'әфьюн',
  'әхлак',
  'жикет',
  'җавап',
  'җаный',
  'җария',
  'җелек',
  'җенес',
  'җепсә',
  'җефет',
  'җәбер',
  'җәзбә',
  'җәймә',
  'җәнап',
  'җәтмә',
  'җигем',
  'җилем',
  'җилен',
  'җиләк',
  'җилән',
  'җилкә',
  'җимеш',
  'җиңсә',
  'җиһаз',
  'җисем',
  'җитен',
  'җомга',
  'җофар',
  'җөйче',
  'җөмлә',
  'җылым',
  'җырчы',
  'җыючы',
  'заман',
  'зарар',
  'зелпе',
  'зәвык',
  'зәһәр',
  'зиһен',
  'зират',
  'зирек',
  'золым',
  'зурат',
  'иблис',
  'иганә',
  'игрек',
  'идарә',
  'изүче',
  'икмәк',
  'илдәш',
  'илһам',
  'илтер',
  'имана',
  'имбир',
  'имгеч',
  'имгәк',
  'имчәк',
  'инҗил',
  'инҗир',
  'инкяр',
  'иннек',
  'инсан',
  'инсаф',
  'иңбаш',
  'иңлек',
  'иптәш',
  'иркәк',
  'иркәм',
  'ирлек',
  'ирләр',
  'ирнәү',
  'исбат',
  'ислам',
  'ислах',
  'испан',
  'ихата',
  'ихлас',
  'ишарә',
  'ишкәк',
  'йогыш',
  'йозак',
  'йолым',
  'йомыш',
  'йонча',
  'йотым',
  'йөгән',
  'йөзек',
  'йөзем',
  'йөкче',
  'йөреш',
  'йөрәк',
  'кабак',
  'кабер',
  'кабык',
  'кабым',
  'кавем',
  'кавык',
  'кавым',
  'кавын',
  'каган',
  'кадак',
  'кадер',
  'каене',
  'каеры',
  'казак',
  'казан',
  'казна',
  'казый',
  'казык',
  'кайгы',
  'кайма',
  'кайне',
  'кайры',
  'кайчы',
  'какма',
  'какчы',
  'калай',
  'калак',
  'каләм',
  'калҗа',
  'калош',
  'калта',
  'калым',
  'калып',
  'камәр',
  'камка',
  'камса',
  'камчы',
  'камыл',
  'камыр',
  'камыт',
  'канат',
  'канун',
  'каһәр',
  'капка',
  'карак',
  'карар',
  'караш',
  'карга',
  'карый',
  'карын',
  'карыш',
  'карья',
  'касык',
  'катиб',
  'катрә',
  'катык',
  'качак',
  'качыр',
  'качыш',
  'кашка',
  'кашык',
  'кәбап',
  'кәбен',
  'кәвеш',
  'кәҗүл',
  'кәйлә',
  'кәлам',
  'кәләш',
  'кәлтә',
  'кәлшә',
  'кәмит',
  'кәнди',
  'кәпәч',
  'кәрәз',
  'кәрлә',
  'кәсеп',
  'кәттә',
  'кәтүк',
  'кәүсә',
  'кәфен',
  'кәшеф',
  'лайла',
  'лачын',
  'лепкә',
  'ләвәш',
  'ләгән',
  'ләңкә',
  'ләтчә',
  'ләүкә',
  'ләүхә',
  'ләхет',
  'майчы',
  'малай',
  'малчы',
  'мамык',
  'маңка',
  'маһир',
  'матдә',
  'матәм',
  'матча',
  'махау',
  'меңче',
  'мәзәк',
  'мәзин',
  'мәкер',
  'мәһәр',
  'мәсәл',
  'мәчет',
  'милек',
  'миләш',
  'мирас',
  'мирза',
  'мисал',
  'мичәү',
  'мичкә',
  'мичче',
  'морҗа',
  'мохит',
  'мөгез',
  'мөдир',
  'мөһер',
  'мөшкә',
  'мулла',
  'мунча',
  'намаз',
  'намус',
  'нарат',
  'нәзер',
  'нәкыш',
  'нәрсә',
  'нәсел',
  'нәсер',
  'нәфес',
  'нәшер',
  'нигез',
  'низаг',
  'никах',
  'ниргә',
  'ногыт',
  'нокта',
  'нотык',
  'нөктә',
  'нөсхә',
  'нугай',
  'нукта',
  'һәвәс',
  'һәлак',
  'һөҗүм',
  'һөнәр',
  'оерша',
  'оеткы',
  'оешма',
  'оҗмах',
  'олтан',
  'орлык',
  'орчык',
  'остаз',
  'очкын',
  'очкыч',
  'очлык',
  'очрак',
  'очучы',
  'оялык',
  'оячык',
  'өермә',
  'өзгеч',
  'өйдәш',
  'өлкәр',
  'өлфәт',
  'өммәт',
  'өндәү',
  'өргеч',
  'өркәч',
  'өрлек',
  'өрфия',
  'өсйөз',
  'өслек',
  'өстәл',
  'өткеч',
  'өтүче',
  'өчаяк',
  'өчлек',
  'өянәк',
  'өянке',
  'парча',
  'патша',
  'пеләш',
  'пестә',
  'печән',
  'пешек',
  'пәрдә',
  'пүлән',
  'пылау',
  'пычак',
  'пычкы',
  'пыяла',
  'рашкы',
  'рәвеш',
  'рәсем',
  'рәсүл',
  'рәхәт',
  'рәхим',
  'ригая',
  'ризык',
  'румын',
  'сабак',
  'сабан',
  'сабый',
  'сабын',
  'савап',
  'савым',
  'савыр',
  'савыт',
  'сагыз',
  'сагыш',
  'сакал',
  'сакчы',
  'салам',
  'салат',
  'салма',
  'салчы',
  'салым',
  'санәм',
  'саңак',
  'сарай',
  'сарут',
  'сарык',
  'сарыф',
  'селте',
  'сеңел',
  'сеңер',
  'серкә',
  'сәбәп',
  'сәдәп',
  'сәҗдә',
  'сәлам',
  'сәмих',
  'сәнәк',
  'сәрви',
  'сәүдә',
  'сәфәр',
  'сәхнә',
  'сәхрә',
  'сидек',
  'сизем',
  'сихер',
  'сихәт',
  'солых',
  'соскы',
  'сөаль',
  'сөзмә',
  'сөлге',
  'сөлек',
  'сөңге',
  'сөрем',
  'сөреш',
  'сөрән',
  'сөрмә',
  'сөтче',
  'сөюче',
  'субай',
  'суган',
  'сугым',
  'сугыш',
  'сулык',
  'сулыш',
  'сумса',
  'сурәт',
  'сусар',
  'сусын',
  'сүсән',
  'сызгы',
  'сызма',
  'сызык',
  'сызым',
  'сыныш',
  'сырга',
  'сыткы',
  'табак',
  'табан',
  'табиб',
  'табут',
  'табын',
  'табыр',
  'табыш',
  'тавис',
  'тавык',
  'тавыш',
  'таган',
  'таҗик',
  'таифә',
  'такта',
  'такыя',
  'талак',
  'талаш',
  'таләп',
  'тамак',
  'тамга',
  'тамчы',
  'тамыр',
  'танау',
  'танык',
  'тарак',
  'тараф',
  'тарих',
  'тасма',
  'татар',
  'татым',
  'ташчы',
  'тезем',
  'тезмә',
  'текмә',
  'телем',
  'теләк',
  'телче',
  'терәк',
  'тәгам',
  'тәмгы',
  'тәмуг',
  'тәңкә',
  'тәңре',
  'тәпән',
  'тәпәч',
  'тәрәт',
  'тәрәч',
  'тәртә',
  'тәүбә',
  'тәхет',
  'тибеш',
  'тизәк',
  'тимер',
  'тирес',
  'тирәк',
  'тирмә',
  'тишек',
  'тоҗым',
  'тозак',
  'тойгы',
  'токым',
  'толып',
  'томан',
  'томау',
  'торак',
  'торба',
  'торма',
  'торна',
  'торыш',
  'тотам',
  'тотка',
  'тотык',
  'тотым',
  'тотыш',
  'төбәк',
  'төймә',
  'төкчә',
  'төлке',
  'төпсә',
  'төрбә',
  'төрек',
  'төрем',
  'төрән',
  'төрки',
  'төрмә',
  'төрчә',
  'төтен',
  'төшем',
  'тубал',
  'тубык',
  'тубыр',
  'тугай',
  'тугым',
  'тузан',
  'туйра',
  'тулма',
  'тупса',
  'тупчы',
  'тупыл',
  'турам',
  'турта',
  'турун',
  'туташ',
  'тутый',
  'тутык',
  'туфан',
  'түтәл',
  'түшәк',
  'түшәм',
  'түшкә',
  'тыныш',
  'тыңчы',
  'тырма',
  'тырыс',
  'тышау',
  'тышча',
  'уелма',
  'уелыш',
  'уенчы',
  'уйгыч',
  'уймак',
  'уйнаш',
  'уклау',
  'укучы',
  'унлык',
  'упкын',
  'ураза',
  'урама',
  'ургыл',
  'ургыч',
  'урман',
  'утлык',
  'утрау',
  'учлам',
  'үзбәк',
  'үзлек',
  'үксез',
  'үлчәм',
  'үрдәк',
  'үрәчә',
  'үрнәк',
  'үрчем',
  'үсмер',
  'үткел',
  'файда',
  'факыр',
  'фалчы',
  'фараз',
  'фарсы',
  'фарыз',
  'фасыл',
  'фатир',
  'фетнә',
  'фәләк',
  'фәһем',
  'фәтва',
  'фәхеш',
  'фидия',
  'фикер',
  'филтә',
  'фирка',
  'фытыр',
  'хаҗәт',
  'хак V',
  'хаким',
  'халәт',
  'халык',
  'ханым',
  'хатын',
  'хәбәр',
  'хәвеф',
  'хәйлә',
  'хәлвә',
  'хәлфә',
  'хәмер',
  'хәреф',
  'хәстә',
  'хәтер',
  'хәтфә',
  'хикәя',
  'хисап',
  'хозур',
  'хокук',
  'холык',
  'хонса',
  'хөкем',
  'хөрмә',
  'хөсет',
  'хөтбә',
  'чабак',
  'чабар',
  'чабыш',
  'чагым',
  'чакма',
  'чалгы',
  'чалу ',
  'чалым',
  'чаңгы',
  'чапкы',
  'чарык',
  'чаткы',
  'чатма',
  'чатыр',
  'чачак',
  'чебен',
  'чебеш',
  'чегән',
  'челән',
  'челлә',
  'черем',
  'черки',
  'чәйче',
  'чәкән',
  'чәмчә',
  'чәүкә',
  'чәчәк',
  'чәчән',
  'чәшке',
  'чигеш',
  'чиләк',
  'чимал',
  'чират',
  'чирек',
  'чирәм',
  'чирәп',
  'читек',
  'читән',
  'чишмә',
  'чокыр',
  'чолан',
  'чолык',
  'чорма',
  'чөлли',
  'чукыр',
  'чулпы',
  'чуман',
  'чумар',
  'чучка',
  'чүбек',
  'чүкеч',
  'чүмеч',
  'чүпрә',
  'чыбык',
  'чыгым',
  'чыгыр',
  'чыгыш',
  'чыпта',
  'чырай',
  'чыршы',
  'чытыр',
  'шаһит',
  'шелтә',
  'шәңгә',
  'шәһәр',
  'шәраб',
  'шәүлә',
  'шәхес',
  'шикәр',
  'шикмә',
  'шимбә',
  'шөбһә',
  'шөпшә',
  'шөреп',
  'шулпа',
  'шыбын',
  'шымчы',
  'шырпы',
  'шытым',
  'ызгыш',
  'ымлык',
  'ындыр',
  'ыргак',
  'ыргым',
  'ыржык',
  'ырмау',
  'ыштан',
  'ыштыр',
  'элгеч',
  'элгәр',
  'элмәк',
  'эңгер',
  'эремә',
  'эрләм',
  'эскәк',
  'этлек',
  'эчлек',
  'эшсез',
  'юалык',
  'юаныч',
  'юклык',
  'юлдаш',
  'юллык',
  'юнучы',
  'юрган',
  'юртак',
  'юртыш',
  'юшкын',
  'ябага',
  'язмыш',
  'язучы',
  'якташ',
  'ялгам',
  'ялгыш',
  'ялкын',
  'янбаш',
  'янгын',
  'янтык',
  'янчык',
  'яңгыр',
  'ярдәм',
  'ярлык',
  'ярчык',
  'ясмин',
  'ясмык',
  'ястык',
  'ятимә',
  'яулык',
  'яфрак',
  'яшәеш',
]
