export const WORDS = [
'акбур',
'гакыл',
'очрак',
'өслек',
'илдәш',
'алыча',
'милек',
'тәрәт',
'качак',
'сызым',
'нәкыш',
'динче',
'сырга',
'тотым',
'мәзәк',
'остаз',
'пүлән',
'сызма',
'алучы',
'табак',
'җелек',
'кәйлә',
'тулма',
'халәт',
'фасыл',
'чишмә',
'сакчы',
'малай',
'икмәк',
'ризык',
'гадәт',
'бүрек',
'җиләк',
'сабын',
'болыт',
'йөзем',
'җимеш',
'фатир',
'җомга',
'бизәк',
'вакыт',
'сәүдә',
'сәхнә',
'шөпшә',
'калай',
'мамык',
'гомер',
'бәрән',
'тамга',
'юлдаш',
'хатын',
'язучы',
'халык',
'әкият',
'йозак',
'урман',
'йөзек',
'тузан',
'гәудә',
'рәвеш',
'савыт',
'үрнәк',
'кабак',
'нокта',
'бодай',
'карга',
'никах',
'чыгыш',
'авыру',
'шимбә',
'язмыш',
'оешма',
'тавык',
'хәреф',
'мунча',
'укучы',
'суган',
'ботка',
'мәчет',
'сөлге',
'түтәл',
'гөмбә',
'ашлык',
'мирас',
'табын',
'алкыш',
'тамчы',
'үрдәк',
'төлке',
'тырма',
'нәсел',
'сагыз',
'чыбык',
'йөрәк',
'тарих',
'фикер',
'тарак',
'миләш',
'аккош',
'шәһәр',
'печән',
'балан',
'ватан',
'балык',
'вафат',
'ястык',
'хикәя',
'яфрак',
'бәлеш',
'заман',
'рәсем',
'бакча',
'гаилә',
'бүлмә',
'тамак',
'хәбәр',
'чирәм',
'якташ',
'җөмлә',
'белем',
'чаңгы',
'чыршы',
'бәхет',
'бабай',
'җавап',
'намус',
'сулыш',
'йомыш',
'гасыр',
'теләк',
'намаз',
'сәфәр',
'чыгым',
'тимер',
'чәчәк',
'бурыч',
'кайчы',
'шулпа',
'яңгыр',
'лачын',
'юрган',
'кадак',
'сабан',
'батыр',
'чиләк',
'бүлек',
'борын',
'тавыш',
'дөнья',
'идарә',
'балта',
'җырчы',
'айгыр',
'торна',
'пычак',
'калым',
'оеткы',
'ураза',
'әртил',
'өчаяк',
'шәүлә',
'инсаф',
'җилен',
'табан',
'юнучы',
'актүш',
'тәртә',
'каһәр',
'факыр',
'карын',
'тугай',
'челлә',
'зелпе',
'казак',
'ниргә',
'чапкы',
'басма',
'тәпәч',
'камыт',
'зират',
'таҗик',
'тибеш',
'кәфен',
'җиһаз',
'гөләп',
'ындыр',
'матдә',
'бәлҗә',
'җитен',
'әүлия',
'кәтүк',
'терәк',
'нәшер',
'ахшам',
'юалык',
'толып',
'ялгыш',
'ятимә',
'астар',
'мичче',
'савыр',
'берек',
'савым',
'аръяк',
'иркәм',
'җәтмә',
'ыштан',
'хөтбә',
'әдрәс',
'шымчы',
'сөзмә',
'бикрә',
'боҗыр',
'борыс',
'эскәк',
'чирек',
'алиһә',
'өлкәр',
'тавис',
'әсбап',
'сәмих',
'сәлам',
'юртак',
'сөтче',
'үксез',
'гөман',
'морҗа',
'сумса',
'һөнәр',
'уелма',
'тубыр',
'тотыш',
'рәхим',
'текмә',
'алдар',
'казый',
'билге',
'эңгер',
'сулык',
'ярлык',
'әрлән',
'зәвык',
'ирлек',
'иңбаш',
'карак',
'кашык',
'салат',
'йотым',
'инҗил',
'ирнәү',
'тотам',
'кәлтә',
'өндәү',
'бүсер',
'ыргым',
'такыя',
'борау',
'чокыр',
'фытыр',
'янтык',
'чукыр',
'бавыр',
'бәхәс',
'танык',
'һәлак',
'үрчем',
'ишарә',
'өянәк',
'богау',
'нукта',
'фалчы',
'дәвер',
'йогыш',
'болан',
'хаҗәт',
'очучы',
'мәзин',
'һәвәс',
'өсйөз',
'олтан',
'иңлек',
'мулла',
'учлам',
'берле',
'хак V',
'кавык',
'тоҗым',
'тубык',
'иркәк',
'чәчән',
'ләтчә',
'юаныч',
'жикет',
'чолан',
'таган',
'ихлас',
'шәраб',
'фарыз',
'тутый',
'чарык',
'өстәл',
'һөҗүм',
'дәлил',
'тыңчы',
'ләңкә',
'башак',
'кәлам',
'нәсер',
'очлык',
'әйрән',
'тирес',
'уймак',
'кәмит',
'кәттә',
'түшкә',
'төбәк',
'сугым',
'төрбә',
'бәвел',
'матәм',
'ишкәк',
'сабый',
'уенчы',
'капка',
'карый',
'аякчу',
'әткәй',
'үлчәм',
'дәвам',
'ермак',
'ыржык',
'упкын',
'әхлак',
'бәйге',
'чабак',
'арчан',
'саңак',
'канат',
'тупыл',
'маһир',
'калош',
'салам',
'дегет',
'хәлфә',
'туфан',
'каене',
'уклау',
'камыл',
'нигез',
'румын',
'чалу ',
'табыш',
'очкын',
'токым',
'илһам',
'сәнәк',
'савап',
'инсан',
'бугаз',
'өянке',
'акбүз',
'йөкче',
'чөлли',
'хокук',
'пылау',
'өлфәт',
'атлам',
'боҗра',
'аркан',
'андыз',
'дәрья',
'торак',
'меңче',
'уелыш',
'исбат',
'юклык',
'ләүкә',
'элгеч',
'челән',
'өрфия',
'карыш',
'катрә',
'калак',
'фәһем',
'мәкер',
'чулпы',
'айлык',
'бурка',
'бибек',
'ыштыр',
'турта',
'кәрәз',
'борма',
'камка',
'гарип',
'юртыш',
'тәңкә',
'җисем',
'качыш',
'хәмер',
'элмәк',
'сәдәп',
'сыткы',
'кавем',
'бакыр',
'черем',
'шикмә',
'чытыр',
'вөҗүд',
'этлек',
'эрләм',
'алмаш',
'рәсүл',
'алаша',
'шаһит',
'арата',
'холык',
'өргеч',
'җәймә',
'чорма',
'бүксә',
'төтен',
'майчы',
'димче',
'сәрви',
'сөреш',
'нөктә',
'казна',
'тәмгы',
'сихәт',
'тирәк',
'кавым',
'өрлек',
'калып',
'файда',
'сугыш',
'борыч',
'бакра',
'пычкы',
'газап',
'артыш',
'варис',
'болак',
'солых',
'ачлык',
'ургыч',
'сусар',
'сурәт',
'алача',
'томау',
'хонса',
'тирмә',
'тубал',
'чалым',
'фетнә',
'аңкау',
'табут',
'белеш',
'ләвәш',
'матча',
'йөгән',
'акшар',
'азлык',
'төрем',
'җәнап',
'ешлык',
'аксым',
'рәхәт',
'сеңер',
'гөслә',
'тараф',
'әрмәк',
'абзый',
'сихер',
'игрек',
'ургыл',
'сарут',
'турам',
'беләк',
'әфьюн',
'казык',
'такта',
'нәфес',
'фәтва',
'сөңге',
'иблис',
'туташ',
'мисал',
'субай',
'зиһен',
'салма',
'аклык',
'эчлек',
'барыш',
'елдаш',
'чатма',
'әнкәй',
'төрек',
'дәрес',
'ләүхә',
'кәрлә',
'хөкем',
'сабак',
'чәүкә',
'тәпән',
'чүпрә',
'тәхет',
'артым',
'читек',
'табыр',
'апара',
'томан',
'уйгыч',
'очкыч',
'ләхет',
'пестә',
'үсмер',
'үзлек',
'әгъза',
'абруй',
'унлык',
'салым',
'бүләк',
'сөюче',
'пыяла',
'мәсәл',
'турун',
'әрләш',
'алтын',
'талаш',
'аерча',
'хөрмә',
'нугай',
'танау',
'җылым',
'сарай',
'шелтә',
'ябага',
'нарат',
'ялкын',
'өркәч',
'утлык',
'зирек',
'җефет',
'табиб',
'ачкыч',
'чаткы',
'маңка',
'шәхес',
'сөлек',
'ислах',
'дөмбе',
'кәвеш',
'эшсез',
'карар',
'дүдәк',
'аслам',
'тезем',
'шыбын',
'камәр',
'шөреп',
'атама',
'хаким',
'сөаль',
'татар',
'гавам',
'лайла',
'тәүбә',
'бәләк',
'бәбкә',
'чәмчә',
'бәбәк',
'бәндә',
'җаный',
'камса',
'әфсен',
'җилем',
'филтә',
'нәрсә',
'пәрдә',
'фидия',
'гидай',
'хәтфә',
'чүбек',
'бетчә',
'юллык',
'чумар',
'бирем',
'имгәк',
'кадер',
'мәһәр',
'җофар',
'ясмык',
'өермә',
'хөсет',
'сагыш',
'мөгез',
'төрки',
'дебет',
'хәйлә',
'биләү',
'җәзбә',
'әрәтә',
'шытым',
'таләп',
'кәшеф',
'әтраф',
'тойгы',
'изүче',
'чатыр',
'мирза',
'казан',
'шырпы',
'касык',
'какма',
'кайгы',
'оҗмах',
'чолык',
'йөреш',
'нәзер',
'кайры',
'сыныш',
'фирка',
'имгеч',
'мичкә',
'мөшкә',
'ясмин',
'калта',
'җыючы',
'абага',
'аклан',
'өткеч',
'гауга',
'утрау',
'бөҗәк',
'җигем',
'өммәт',
'сүсән',
'хозур',
'йонча',
'җөйче',
'ыргак',
'чабар',
'нотык',
'бүлем',
'сарык',
'бирән',
'чучка',
'галим',
'сөрмә',
'ботак',
'тупчы',
'орчык',
'сөрем',
'алдак',
'имана',
'фарсы',
'яшәеш',
'чирәп',
'уйнаш',
'бозау',
'серкә',
'элгәр',
'инкяр',
'чабыш',
'оялык',
'тозак',
'гамәл',
'атучы',
'пеләш',
'әрмән',
'чебен',
'зәһәр',
'оерша',
'нөсхә',
'богыл',
'давыл',
'кашка',
'кавын',
'тасма',
'чыгыр',
'испан',
'төпсә',
'иганә',
'качыр',
'ләгән',
'кабым',
'тугым',
'азман',
'рашкы',
'буран',
'ызгыш',
'төрмә',
'аркау',
'имчәк',
'чакма',
'бөреш',
'сәхрә',
'соскы',
'чират',
'божан',
'ярчык',
'канун',
'санәм',
'телче',
'вәкил',
'оячык',
'ымлык',
'чалгы',
'үрәчә',
'каеры',
'төрән',
'болын',
'сәҗдә',
'кайне',
'шикәр',
'чагым',
'фараз',
'ярдәм',
'черки',
'тотык',
'каган',
'әлүчә',
'калҗа',
'иннек',
'төрчә',
'кәпәч',
'тышау',
'каләм',
'чегән',
'кәүсә',
'эремә',
'парча',
'сакал',
'зурат',
'камчы',
'гөблә',
'галәм',
'җәбер',
'җария',
'камыр',
'тышча',
'бурят',
'илтер',
'бәпкә',
'тәмуг',
'кабер',
'бәдән',
'тамыр',
'фәләк',
'беләү',
'сәбәп',
'туйра',
'өйдәш',
'ташчы',
'ногыт',
'хәвеф',
'чимал',
'абхаз',
'чүмеч',
'ислам',
'катык',
'үткел',
'тәңре',
'чуман',
'дистә',
'бишек',
'чыпта',
'мөдир',
'борча',
'какчы',
'булат',
'тезмә',
'мохит',
'түшәм',
'золым',
'белен',
'дисбе',
'мичәү',
'сидек',
'кәҗүл',
'бәкәл',
'зарар',
'талак',
'торыш',
'ихата',
'чүкеч',
'базар',
'салчы',
'тупса',
'ырмау',
'чәйче',
'түшәк',
'урама',
'торма',
'гөнаһ',
'сеңел',
'юшкын',
'биюче',
'шәңгә',
'тотка',
'телем',
'тутык',
'төкчә',
'чебеш',
'бөрмә',
'таифә',
'малчы',
'даирә',
'тәгам',
'селте',
'төшем',
'сарыф',
'янчык',
'хәстә',
'кәнди',
'үзбәк',
'чырай',
'әнчек',
'кабык',
'җепсә',
'караш',
'тизәк',
'кәбап',
'мөһер',
'патша',
'тыныш',
'өзгеч',
'яулык',
'лепкә',
'кәбен',
'букча',
'хәлвә',
'шөбһә',
'бирнә',
'сусын',
'даган',
'низаг',
'карья',
'имбир',
'быргы',
'янгын',
'аккүз',
'гозер',
'катиб',
'чигеш',
'тәрәч',
'җилкә',
'әйбер',
'өтүче',
'кәсеп',
'җилән',
'вәзир',
'бәбәй',
'йолым',
'татым',
'акула',
'тишек',
'төймә',
'өчлек',
'балак',
'сызык',
'ригая',
'хәтер',
'бистә',
'махау',
'пешек',
'борай',
'аракы',
'орлык',
'торба',
'сызгы',
'тырыс',
'дәлия',
'сизем',
'аршын',
'кәлшә',
'ялгам',
'гарәп',
'читән',
'фәхеш',
'чәкән',
'ханым',
'инҗир',
'кайма',
'аерма',
'чачак',
'җиңсә',
'ирләр',
'янбаш',
'абзар',
'сөрән',
'аслык',
'хисап',
'җенес',
'басым',
'чәшке',
'бүкән',
'иптәш',
'кәләш',
]
