import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { Cell } from '../grid/Cell'
import { XIcon } from '@heroicons/react/outline'


type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const InfoModal = ({ isOpen, handleClose }: Props) => {
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={handleClose}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
              <div className="absolute right-4 top-4">
                <XIcon
                  className="h-6 w-6 cursor-pointer text-neutral-500"
                  onClick={() => handleClose()}
                />
              </div>
              <div>
                <div className="text-center">
                  <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 font-medium text-green-700"
                  >
                    УЕН КАГЫЙДӘЛӘРЕ
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500 text-left">
                    Серле сүзне алты омтылыштан белегез.<br></br>
                    Һәр омтылыштан соң серле сүздә нинди хәрефләр булуын күрсәтү өчен хәрефләрнең төсе үзгәрәчәк!
                    </p>
                    <hr className="mt-3"></hr>

                    <div className="flex justify-center mb-1 mt-4">
                      <Cell value="Я" status="correct" />
                      <Cell value="Ң" />
                      <Cell value="Г" />
                      <Cell value="Ы" />
                      <Cell value="Р" />
                    </div>
                    <p className="text-sm text-gray-500 text-left">
                    <b>Я</b> хәрефе серле сүздә бар һәм дөрес урынында тора.
                    </p>

                    <div className="flex justify-center mb-1 mt-4">
                      <Cell value="Ю" />
                      <Cell value="Л" />
                      <Cell value="Д" status="present" />
                      <Cell value="А" />
                      <Cell value="Ш" />
                    </div>
                    <p className="text-sm text-gray-500 text-left">
                    <b>Д</b> хәрефе серле сүздә бар, ләкин ул урынында тормый.
                    </p>

                    <div className="flex justify-center mb-1 mt-4">
                      <Cell value="Й" />
                      <Cell value="Ө" />
                      <Cell value="З" />
                      <Cell value="Е" />
                      <Cell value="К" status="absent" />
                    </div>
                    <p className="text-sm text-gray-500 text-left">
                    <b>К</b> хәрефе серле сүздә юк.
                    </p>
                    <hr className="mt-3"></hr>
                    <div className="flex justify-center mb-1 mt-4">
                    <p className=" text-lg text-green-700 "><b>Көн саен яңа сүз туа!</b></p>
                    <button
        type="button"
        className="items-center ml-4 px-4 py-1.5 border border-transparent text-xs font-medium rounded text-white bg-green-700 hover:bg-green-500 outline-none"
        onClick={() => handleClose()}
      >
        УЙНАРГА
      </button>
      </div>
                    
                    
                    
                    
                    
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
